import { ScrollRestoration, useNavigate } from "react-router-dom";
import { SEDES } from "../data/SEDES"



export default function Sedes() {
    const navigate = useNavigate();

    return (
        <>
            <ScrollRestoration />
            <section style={{ position: "relative", paddingTop: "5rem" }}>
                <div className="mt-5 py-5 text-white text-center">
                    <div className="fs-1 fw-bold text-shadow-embossed">SEDES</div>
                </div>
                <img className="img-bg-container img-filter-bright-75" alt="header"
                    src="/images/fotor_2023-4-16_8_9_59.jpg" />
            </section>
            <section className="b2-container py-5">
                <div className="row">
                    {SEDES.map(sede => {
                        return (
                            <div key={sede.id} className="col-sm-6 col-lg-4 col-xxl-3 mb-4">
                                <div className='mx-1 hover-scale shadow-sm bg-white h-100' onClick={() => navigate("/sedes/" + sede.id, { preventScrollReset: false })}
                                    style={{ cursor: "pointer", position: "relative" }}>
                                    <img style={{ height: "12rem", width: "100%", objectFit: "cover", display: "block" }}
                                        alt="sede" src={sede.photo ? sede.photo : "sven-mieke-tc1eFr68CTU-unsplash.jpg"} />
                                    <div className='p-3'>
                                        <p className='fs-4 fw-bold mb-2'>{sede.name}</p>
                                        <p className='mb-2'><i className="bi bi-geo-alt-fill text-primary"></i> {sede.address}</p>
                                        <p className='mb-2'><i className="bi bi-whatsapp text-primary"></i> {sede.phone}</p>
                                        {sede.email && <p className='mb-1'><i className="bi bi-at text-primary"></i> {sede.email}</p>}
                                        {/* <p className="fw-light">
                                        {sede.description}
                                    </p> */}
                                    </div>
                                    <div className='fs-5 fw-bold text-primary' style={{ position: "absolute", right: "1rem", bottom: "0.5rem" }}>
                                        <i className="bi bi-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </section>
        </>
    )
}